let m = require('mithril');

let mPost = require('../models/mPost');

module.exports = {
    view: function(vnode) {	
	return [
	    m('section.pageSelector', mPost.pagination.ui),
	    m('p', [
		m('span.cryptedmail', {
		    'data-name': 'eric',
		    'data-domain': 'ericwebsite',
		    'data-tld': 'info',
		    onclick: function() {
			window.open('mailto:' + this.dataset.name
				    + '@' + this.dataset.domain + '.' + this.dataset.tld);
			return false;
		    },
		    onmouseover: function() {
			this.textContent = this.dataset.name
			    + '@' + this.dataset.domain + '.' + this.dataset.tld;
		    },
		    onmouseout: function() {
			this.textContent = 'Eric Pledger'
		    }
		}, 'Eric Pledger'),
		m.trust(' © ' + new Date().getFullYear())
	    ])
	];
    }
}
