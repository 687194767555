let m = require('mithril');

module.exports = {
    view: function(vnode) {
	return m('header', m('hContent', [
	    m(m.route.Link, { href: '/' }, m('h1', m.trust(vnode.attrs.title))),
	    (vnode.attrs.sub) ? vnode.attrs.sub.map((sub) => { return sub }) : null
	]))
    }
}
