let m = require('mithril');

let mPage = require('../models/mPage');

module.exports = {
    oninit: function(vnode) {
	mPage.loadList(vnode.attrs.filter);
    },
    
    view: function(vnode) {
	return m('nav', m('ul', [
	    mPage.list.map((page) => {
		return m('li',
			 m(m.route.Link,
			  { href: '/page/' + page.slug },
			   '> ' +page.title)
			)		
	    })
	]))
    }
}
