let m = require('mithril');

let mPost = require('../models/mPost');
let mPage = require('../models/mPage');

module.exports = {
    oninit: function(vnode) {
	if (vnode.attrs.slug)
	{
	    mPost.load(vnode.attrs.slug);
	}

	if (vnode.attrs.page_slug)
	{
	    mPage.load(vnode.attrs.page_slug);
	}
    },

    onupdate: function(vnode) {
	if (vnode.attrs.slug && (mPost.current.slug != vnode.attrs.slug))
	{
	    mPost.current.slug = vnode.attrs.slug;
	    mPost.load(vnode.attrs.slug);
	}

	if (vnode.attrs.page_slug && (mPage.current.slug != vnode.attrs.page_slug))
	{
	    mPage.current.slug = vnode.attrs.page_slug;
	    mPage.load(vnode.attrs.page_slug);
	}
    },
    
    view: function(vnode) {
	let title = "Loading...";
	let created_at = "Loading...";
	let html = "Loading...";
	let url = '';
	
	if (vnode.attrs.post)
	{
	    let post = vnode.attrs.post;
	    
	    title = post.title;
	    created_at = new Date(post.created_at).toLocaleString();
	    html = post.html;
	    url = `/post/${post.slug}`;
	}

	if (vnode.attrs.slug)
	{
	    title = mPost.current.title;
	    created_at = new Date(mPost.current.created_at).toLocaleString();
	    html = mPost.current.html;
	    url = `/post/${vnode.attrs.slug}`;
	}

	if (vnode.attrs.page_slug)
	{
	    title = mPage.current.title;
	    created_at = new Date(mPage.current.created_at).toLocaleString();
	    html = mPage.current.html;
	    url = `/page/${vnode.attrs.page_slug}`;
	}

	return m('article', [
	    m('.postContent', [		
		m.trust(html)
	    ]),
	    m(m.route.Link, {href:url}, '∴')
	])
    }
}
