let m = require("mithril");

function buildFilter(filter) {
    if (!filter)
	return ''
    
    let oFilter = ''    

    if ('tags' in filter) {
	oFilter += 'tags:[' + filter.tags.join('+') + ']';
    }

    if (oFilter != '')
	oFilter = '&filter=' + oFilter;

    return oFilter
}

function scrollTop()
{
    window.scroll(0,0);
}

function pagination(c, mm) {
    if (mm == 1)
	return [];
    
    var current = c,
        last = mm,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;
	
    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    let out = [];    
    rangeWithDots.forEach((val) => {
	if (val === '...') {
	    out.push(m('span.pg', '...'));
	} else if (val === c) {
	    out.push(m('b.pg', val));
	} else {	
	    out.push(m(m.route.Link, { class: 'pg', href: `/${val}`, onclick:scrollTop }, val));
	}
    });

    if (c !== 1)
	out.unshift(m(m.route.Link, { class: 'pg', href: `/${c-1}`, onclick:scrollTop }, '🡄'))

    if (c !== mm)
	out.push(m(m.route.Link, { class: 'pg', href: `/${c+1}`, onclick:scrollTop }, '🡆'))

    return out;
}

let mPost = {
    list: [],
    pagination: {ui:[]},
    
    loadList: (page=1, filter={}) => {	
	return m.request({
	    method: "GET",
	    url: `${API_BASE}/content/posts/?key=${API_KEY}&page=${page}` + buildFilter(filter)
	}).then((result) => {	    
	    mPost.list = result.posts;
	    mPost.pagination = result.meta.pagination;

	    mPost.pagination.ui = pagination(result.meta.pagination.page, result.meta.pagination.pages);
	})
    },

    current: {},
    
    load: (slug) => {	
	return m.request({
	    method: "GET",
	    url: `${API_BASE}/content/posts/slug/${slug}/?key=${API_KEY}`
	}).then((result) => {
	    mPost.current = result.posts[0];
	})
    }
}

module.exports = mPost;
